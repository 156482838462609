import React from 'react'
import { navigate } from 'gatsby'

// graphQL
import { getGraphChapters } from '../../../graphQL'

// Models
import { SeriesModel } from '../../../models'

// Config
import { end } from '../../../config/localization'

// Utils
import { getFont } from '../../../utils/utils'

// Style
import {
  blueWrapper,
  blockContainer,
  endCta,
  endCtas,
  endLink,
  endTitle,
  endNoLink,
  endContainer,
  endDescription,
} from './styles.module.scss'

interface Props {
  series: SeriesModel
  language: string
}

const EndOfSeries: React.FC<Props> = ({ series, language }) => {
  const nextComic = getGraphChapters().find((comic) => {
    return (
      comic.publicationDate === 'XXXX/XX/XX' &&
      Number(series.id) === Number(comic.series.id)
    )
  })

  return (
    <>
      <div className={`block-wrapper ${blueWrapper}`}>
        <div className={`container ${blockContainer}`}>
          <h3 className={`${endTitle} ${getFont('font-patua')}`}>
            {end[language].title}
          </h3>
          <p className={`${endDescription} ${getFont('font-lato')}`}>
            {end[language].description}
            <span
              className={endLink}
              onClick={() => {
                navigate('/support')
              }}
            >
              {end[language].link}
            </span>
          </p>
          <div className={`${endContainer} ${getFont('font-lato')}`}>
            <p>
              {end[language].next}
              <i>{`${end[language].chapter} ${nextComic?.number} - ${nextComic?.title[language]}`}</i>
            </p>
            <p>{nextComic?.description[language]}</p>
            <div className={endCtas}>
              <p
                className={endCta}
                onClick={() => {
                  navigate('/world/story')
                }}
              >
                {end[language].series}
              </p>
              <p
                className={endCta}
                onClick={() => {
                  window.scrollTo(0, document.body.scrollHeight)
                }}
              >
                {end[language].follow_us}
              </p>
              <p className={endCta}>
                <a
                  target="_blank"
                  href="https://discord.gg/xBmcvjKm3m"
                  className={endNoLink}
                >
                  {end[language].discord}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EndOfSeries
